export default defineNuxtRouteMiddleware((to, from) => {
  const layoutStore = useLayoutStore();

  if (
    (to.name as string | null | undefined)?.includes('login') &&
    (from.name as string | null | undefined)?.includes('dashboard')
  ) {
    layoutStore.setAfterLoginPage({ page: from });
  }
});
